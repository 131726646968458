import { Link as MuiLink, LinkProps, SxProps } from '@mui/material';
import { ElementType, HTMLAttributeAnchorTarget } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { getLinkAttributes } from '../../utils/tools';

const LINK_BASE_STYLE: SxProps = {
  fontWeight: 700
};

type CustomLinkProps = LinkProps & {
  to?: string;
  target?: HTMLAttributeAnchorTarget;
  component?: ElementType;
};

export const Link = ({
  variant = 'body1',
  to,
  href,
  target,
  children,
  sx = {},
  onClick,
  component
}: CustomLinkProps) => {
  const linkSx = {
    ...LINK_BASE_STYLE,
    ...sx
  };
  const componentToUse = component ?? (to ? RouterLink : 'a');
  return (
    <MuiLink
      onClick={onClick}
      variant={variant}
      component={componentToUse}
      to={to}
      href={href}
      children={children}
      sx={linkSx}
      {...getLinkAttributes(!to, target)}
    />
  );
};
