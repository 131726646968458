import { SvgIcon } from '@mui/material';

type IconChevroProps = { orientation?: 'right' | 'left' | 'top' | 'bottom' };

export const IconChevron = ({ orientation = 'bottom' }: IconChevroProps) => {
  let rotation = 'rotate(0deg)';
  switch (orientation) {
    case 'top':
      rotation = 'rotate(180deg)';
      break;
    case 'left':
      rotation = 'rotate(-90deg)';
      break;
    case 'right':
      rotation = 'rotate(90deg)';
      break;
    default:
      break;
  }
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" style={{ transform: rotation }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5006 9.00028L18.7324 10.3143L11.5188 17.3288L3.99995 10.314L5.23179 9L11.5188 14.7008L17.5006 9.00028Z"
      />
    </SvgIcon>
  );
};
