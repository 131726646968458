import { Breakpoint } from '@mui/material';
import { useEffect, useState } from 'react';
import theme from '../theme';

export const useViewport = (breakpoint: Breakpoint = 'md') => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return {
    width,
    isSmallViewport: width < theme.breakpoints.values[breakpoint]
  };
};
