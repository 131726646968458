import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ArrowLink } from '../components/atoms/ArrowLink';
import { Page } from '../components/atoms/Page';
import { localizeUrl } from '../utils/tools';

export const NotAvailableInCurrentLanguage = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <Container>
        <Typography>{t('layout.notAvailable')}</Typography>
        <ArrowLink to={localizeUrl('/')} label={t('layout.toHomepage')} isBackArrow />
      </Container>
    </Page>
  );
};

export default NotAvailableInCurrentLanguage;
