import { Container as MuiContainer, ContainerProps, SxProps } from '@mui/material';

export const CONTAINER_PADDING_XS = '1.25rem';
export const CONTAINER_PADDING_SM = '2rem';
export const CONTAINER_PADDING_MD = '3rem';
export const CONTAINER_PADDING: SxProps = {
  px: {
    xs: CONTAINER_PADDING_XS,
    sm: CONTAINER_PADDING_SM,
    md: CONTAINER_PADDING_MD
  }
};

const CONTAINER_STYLES: { [key: string]: SxProps } = {
  fullWidth: {
    maxWidth: { xs: 'none' },
    ...CONTAINER_PADDING
  },
  large: {
    maxWidth: {
      xs: '90rem' // 1440px
    },
    ...CONTAINER_PADDING
  },
  narrow: {
    maxWidth: {
      xs: '75rem' // 1200px
    },
    ...CONTAINER_PADDING
  }
};

type CustomContainerProps = {
  containerType?: keyof typeof CONTAINER_STYLES;
};

export const Container = ({ containerType = 'large', sx = {}, ...props }: ContainerProps & CustomContainerProps) => {
  const containerStyle = { ...CONTAINER_STYLES[containerType], ...sx };
  return <MuiContainer {...props} sx={containerStyle} />;
};
