import { SvgIcon } from '@mui/material';

export const IconClose = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24">
    <path d="M15.4696 16.5303L16 17.0606L17.0606 16L16.5303 15.4696L15.4696 16.5303ZM8.53033 7.46967L8 6.93934L6.93934 8L7.46967 8.53033L8.53033 7.46967ZM16.5303 15.4696L8.53033 7.46967L7.46967 8.53033L15.4696 16.5303L16.5303 15.4696Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0606 7.99999L7.99987 17.0607L6.93921 16L15.9999 6.93933L17.0606 7.99999Z"
    />
  </SvgIcon>
);
