import { Box } from '@mui/material';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HEADER_DROPDOWN_MENU_ID, HEADER_HEIGHT } from '../../constants/layout';
import { PROFIL } from '../../constants/routing';
import { useViewport } from '../../hooks/useViewport';
import { ReactLink } from '../../models/common';
import { StrapiEnTeteEntity } from '../../models/layout';
import { transformHeaderLinks } from '../../utils/layout';
import { localizeUrl } from '../../utils/tools';
import { IconButton } from '../atoms/IconButton';
import { Image } from '../atoms/Image';
import { LanguageSelector } from '../atoms/LanguageSelector';
import { Link } from '../atoms/Link';
import { IconBurgerMenu } from '../icons/IconBurgerMenu';
import { IconUser } from '../icons/IconUser';
import { DropdownMenu } from '../molecules/header/DropdownMenu';
import { HeaderLinksDesktop } from '../molecules/header/HeaderLinksDesktop';
import { LoggedOutButtons } from '../molecules/header/LoggedOutButtons';
import { MainBurgerMenu } from '../molecules/header/MainBurgerMenu';
import { UserBurgerMenu } from '../molecules/header/UserBurgerMenu';
import { Container } from './Container';

export type HeaderProps = {
  enTete?: StrapiEnTeteEntity;
  locale: string;
  isLoggedIn?: boolean;
};

// Added <Suspense> around components declarations to fix lazy loading on window resize
const Drawer = lazy(() => import('@mui/material/Drawer'));
const DrawerRight = lazy(() => import('../atoms/DrawerRight'));

export const Header = ({ enTete, locale, isLoggedIn }: HeaderProps) => {
  const { t } = useTranslation();
  const { isSmallViewport } = useViewport();
  const [isMainBurgerMenuOpen, setIsMainBurgerMenuOpen] = useState(false);
  const [isUserBurgerMenuOpen, setIsUserBurgerMenuOpen] = useState(false);

  const [dropdownLinks, setDropdownLinks] = useState<ReactLink[]>([]);
  const [dropdownIndex, setDropdownIndex] = useState<number | undefined>();

  const handleDropdown = (sublinks: ReactLink[], index?: number) => {
    setDropdownLinks(sublinks);
    setDropdownIndex(index);
  };

  useEffect(() => {
    if (dropdownLinks.length !== 0) {
      //Focus first link of submenu when we open it (for accessibility)
      document.getElementById(HEADER_DROPDOWN_MENU_ID)?.getElementsByTagName('a')[0].focus();
    }
  }, [dropdownLinks]);

  if (!enTete) return null;
  const headerLinks = transformHeaderLinks(enTete.attributes.LienMenus ?? []);

  const ariaLabelMenuDisplay = t('menu.display');
  return (
    <Box
      component="header"
      sx={{
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        height: HEADER_HEIGHT,
        width: '100%',
        backgroundColor: '#fff'
      }}
    >
      {/* Desktop Header */}
      {!isSmallViewport && (
        <>
          <Box
            sx={{
              zIndex: 1000,
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              backgroundColor: '#fff'
            }}
          >
            <Container>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {/* Logo */}
                <Box sx={{ flex: '0 0 7rem', width: '7rem', display: 'flex', alignItems: 'center' }}>
                  <Link
                    to={localizeUrl('/', isLoggedIn)}
                    sx={{ display: 'inline-block', width: '100%', lineHeight: 0 }}
                  >
                    <Image src="/images/logo.png" alt="logo" sx={{ height: 'auto', width: '100%' }} />
                  </Link>
                </Box>

                {/* Links */}
                <Box
                  component="nav"
                  role="navigation"
                  sx={{
                    flex: '0 1 60rem',
                    px: '5rem',
                    pr: '3rem',
                    mx: 'auto'
                  }}
                >
                  <HeaderLinksDesktop
                    links={headerLinks}
                    dropdownVisible={dropdownLinks.length !== 0}
                    setDropdown={(sublinks, index) => {
                      // If there are sublinks, and selected submenu index is not the same
                      // Display sublinks
                      if (sublinks.length !== 0 && index !== dropdownIndex) {
                        handleDropdown(sublinks, index);
                      }
                      // Else, empty dropdown if not already empty
                      else if (dropdownLinks.length !== 0) {
                        handleDropdown([]);
                      }
                    }}
                  />
                </Box>

                {/* Lang, SignUp, SignIn */}
                <Box
                  sx={{
                    flex: '0 0 auto',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    columnGap: '2rem'
                  }}
                >
                  <LanguageSelector locale={locale} />
                  <Box sx={{ display: 'flex', columnGap: '1rem' }}>
                    {isLoggedIn ? (
                      <>
                        {/* <SearchField /> */}
                        <IconButton iconButtonType="light" to={localizeUrl(`/${PROFIL}/profil`, true)}>
                          <IconUser />
                        </IconButton>
                      </>
                    ) : (
                      <LoggedOutButtons />
                    )}
                  </Box>
                </Box>
              </Box>
            </Container>
          </Box>
          {dropdownLinks.length !== 0 && (
            <DropdownMenu dropdownLinks={dropdownLinks} onClick={() => handleDropdown([])} />
          )}
        </>
      )}

      {/* Mobile Header */}
      {isSmallViewport && (
        <Container>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItem: 'center', my: '1.5rem' }}>
            {/* Main burger menu */}
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <IconButton
                aria-label={ariaLabelMenuDisplay}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  ml: '-0.75rem'
                }}
                onClick={() => {
                  setIsMainBurgerMenuOpen(true);
                }}
              >
                <IconBurgerMenu />
              </IconButton>
              <Suspense>
                <Drawer
                  PaperProps={{
                    sx: {
                      width: '100%'
                    }
                  }}
                  anchor="left"
                  open={isMainBurgerMenuOpen}
                >
                  <MainBurgerMenu
                    headerLinks={headerLinks}
                    isLoggedIn={isLoggedIn}
                    onMenuClose={() => {
                      setIsMainBurgerMenuOpen(false);
                    }}
                    locale={locale}
                  />
                </Drawer>
              </Suspense>
            </Box>

            {/* Logo */}
            <Box sx={{ flex: '0 1 7rem', maxWidth: '7rem', display: 'flex', justifyContent: 'center' }}>
              <Link to={localizeUrl('/', isLoggedIn)} sx={{ display: 'inline-block', lineHeight: 0 }}>
                <Image src="/images/logo.png" alt="logo" sx={{ height: '4rem' }} />
              </Link>
            </Box>

            {/* User menu */}
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {isLoggedIn && (
                <>
                  <IconButton
                    aria-label={ariaLabelMenuDisplay}
                    iconButtonType="light"
                    onClick={() => {
                      setIsUserBurgerMenuOpen(true);
                    }}
                  >
                    <IconUser />
                  </IconButton>
                  <Suspense>
                    <DrawerRight
                      open={isUserBurgerMenuOpen}
                      onClose={() => {
                        setIsUserBurgerMenuOpen(false);
                      }}
                    >
                      <UserBurgerMenu
                        onMenuClose={() => {
                          setIsUserBurgerMenuOpen(false);
                        }}
                      />
                    </DrawerRight>
                  </Suspense>
                </>
              )}
            </Box>
          </Box>
        </Container>
      )}
    </Box>
  );
};
