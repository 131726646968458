import { ReactLink } from '../models/common';
import { LienMenu } from '../models/layout';
import { transformLink, transformLinks } from './tools';

export type LinkOrMenu = ReactLink & {
  subLinks?: ReactLink[];
};

export const transformHeaderLinks = (LienMenus: LienMenu[]): LinkOrMenu[] => {
  return LienMenus.map(({ Lien, Label, SousMenus }) => {
    return Lien ? transformLink({ Label, Lien }) : { label: Label, subLinks: transformLinks(SousMenus) };
  });
};
