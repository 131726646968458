import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { LOGIN_BUTTON_ID } from '../../../constants/layout';
import { FORMULAIRE_INSCRIPTION, LOGIN } from '../../../constants/routing';
import { localizeUrl } from '../../../utils/tools';
import { Button } from '../../atoms/Button';
import { useSignInReport } from '../../../hooks/analytic/useSignInReport';
import { useState } from 'react';
import { useSignUpReport } from '../../../hooks/analytic/useSignUpReport';

type LoggedOutButtonsProps = {
  isSignupFirst?: boolean;
  isHeader?: boolean;
};

export const LoggedOutButtons = ({ isSignupFirst = false, isHeader = true }: LoggedOutButtonsProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [eventSignIn, setEventSignIn] = useState('');
  const [eventSignUp, setEventSignUp] = useState('');
  useSignInReport({ event: eventSignIn, userId: '' });
  useSignUpReport({ event: eventSignUp, setFn: setEventSignUp });
  const locale = location.pathname.split('/')[1];

  return (
    <>
      <Button
        href={`${LOGIN}?language=${locale}`}
        id={isHeader ? LOGIN_BUTTON_ID : `${LOGIN_BUTTON_ID}_other`}
        target="_self"
        variant="outlined"
        sx={{ order: 2 }}
        onClick={() => {
          setEventSignIn('security_sign_in_attempt');
        }}
      >
        {t('header.signin')}
      </Button>
      <Button
        to={localizeUrl(`/${FORMULAIRE_INSCRIPTION}`)}
        variant="contained"
        sx={{ order: isSignupFirst ? 1 : 3 }}
        onClick={() => {
          setEventSignUp('security_sign_up_attempt');
        }}
      >
        {t('header.signup')}
      </Button>
    </>
  );
};
