import { createTheme } from '@mui/material';
import { StrapiSavoirType } from './models/thematique';

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      // UI
      interface: {
        inputBorder: string;
        progress: string;
        badge: string;
      };
      // Thematiques
      thematiques: {
        [key in StrapiSavoirType]: { light: string; main: string };
      };
      //EMB
      europeanMicrobiotaBiotics: { light: string; main: string };
      //Savoir-faire
      savoirFaire: { light: string; main: string };
    };
  }
  interface PaletteOptions {
    custom: {
      // UI
      interface: {
        inputBorder: string;
        progress: string;
        badge: string;
      };
      // Thematiques
      thematiques: {
        [key in StrapiSavoirType]: { light: string; main: string };
      };
      //EMB
      europeanMicrobiotaBiotics: { light: string; main: string };
      //Savoir-faire
      savoirFaire: { light: string; main: string };
    };
  }
}

const theme = createTheme({
  spacing: 16,
  breakpoints: {
    // Default MUI values
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  palette: {
    primary: {
      light: '#D8F3FF',
      main: '#0084C8',
      dark: '#006AA0'
    },
    secondary: {
      main: '#002D59'
    },
    success: {
      light: '#23AD0C',
      main: '#00641C'
    },
    error: {
      light: '#BC1046',
      main: '#BE0000'
    },
    background: {
      default: '#FFFFFF',
      paper: '#EFF3FA'
    },
    text: {
      primary: '#3A3A3A',
      secondary: '#747474'
    },
    custom: {
      // UI
      interface: {
        inputBorder: '#94A3B8',
        progress: '#E23287',
        badge: '#FFDC56'
      },
      // Thematiques
      thematiques: {
        microbiotes: { light: '#EEBCD2', main: '#C61F69' },
        micronutritionNutrition: { light: '#EFD9C6', main: '#D67E3B' },
        substancesVegetales: { light: '#B2CCE3', main: '#3963AA' },
        ressources: { light: '#EBB7C7', main: '#BC1046' },
        medeis: { light: '#B2DAD6', main: '#008575' }
      },
      // EMB
      europeanMicrobiotaBiotics: { light: '#B2C0CD', main: '#002D59' },
      // Savoir-Faire
      savoirFaire: { light: '#EAD8F5', main: '#952ED4' }
    }
  },
  shape: {
    borderRadius: 8
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 16,
    fontFamily: 'Open Sans Regular, Arial',
    h2: {
      fontFamily: 'Open Sans Regular, Arial, sans-serif',
      fontWeight: 400,
      fontSize: '1.625rem',
      lineHeight: '1.25'
    },
    h3: {
      fontFamily: 'Open Sans SemiBold, Arial, sans-serif',
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: '1.5'
    },
    body1: {
      fontFamily: 'Open Sans Regular, Arial, sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.5'
    },
    body2: {
      fontFamily: 'Open Sans Regular, Arial, sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.5'
    },
    button: {
      fontFamily: 'Open Sans Bold, Arial, sans-serif',
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '1.5',
      textTransform: 'none'
    }
  }
});

// Define theme variables here to have responsive values

theme.typography.h1 = {
  fontFamily: 'Open Sans Regular, Arial, sans-serif',
  fontWeight: 400,
  fontSize: '2.5rem',
  lineHeight: '1.4',
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem'
  }
};

export default theme;
