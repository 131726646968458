import { Box } from '@mui/material';
import { HEADER_LINK_STYLE } from '../../../constants/layout';
import { ReactLink } from '../../../models/common';
import { LinkOrMenu } from '../../../utils/layout';
import { Button } from '../../atoms/Button';

export type HeaderLinksDesktopProps = {
  links: LinkOrMenu[];
  dropdownVisible: boolean;
  setDropdown: (sublinks: ReactLink[], index?: number) => void;
};

export const HeaderLinksDesktop = ({ links, setDropdown }: HeaderLinksDesktopProps) => (
  <Box
    role="list"
    sx={{
      display: 'flex',
      columnGap: '3rem',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}
  >
    {links.map(({ label, to, href, subLinks }, index) => {
      return (
        <Button
          role="listitem"
          key={label}
          to={to}
          href={href}
          onClick={() => {
            // If item has sublinks, trigger setDropdown action
            if (subLinks && subLinks.length !== 0) {
              setDropdown(subLinks, index);
            }
            // Else, empty dropdown
            else {
              setDropdown([]);
            }
          }}
          sx={{ ...HEADER_LINK_STYLE, maxWidth: '9rem' }}
        >
          {label}
        </Button>
      );
    })}
  </Box>
);
