import { SxProps } from '@mui/material';
import theme from '../theme';

// Login button id for Fruggr
export const LOGIN_BUTTON_ID = 'app-login';

// Header
export const HEADER_DROPDOWN_MENU_ID = 'header-dropdown-menu';
export const HEADER_HEIGHT = '7rem';
export const HEADER_LINK_STYLE: SxProps = {
  justifyContent: 'flex-start',
  p: 0,
  fontFamily: theme.typography.h3.fontFamily,
  fontWeight: 600,
  textAlign: 'left',
  '&:hover': {
    backgroundColor: 'transparent'
  }
};

// Footer
export const FOOTER_HEIGHT = '13.75rem';
