import { Box, SxProps } from '@mui/material';
import { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import { useImageFormats } from '../../hooks/useImageFormats';
import { StrapiImage } from '../../models/common';
import { StrapiImageFormat } from '../../models/image';
import { IconSearch } from '../icons/IconSearch';
import { IconButton } from './IconButton';
import { Image } from './Image';

type ImageStrapiProps = {
  strapiImage?: StrapiImage;
  isZoomable?: boolean;
  selectedFormat?: StrapiImageFormat;
  alt?: string;
  sx?: SxProps;
  /**
   * Aspect ratio in percentage
   * X : width, Y: height
   * X:Y = X / Y
   * 16:9 = 56.25%
   * 4:3 = 75%
   */
  aspectRatio?: string;
};

export const ImageStrapi = ({
  strapiImage,
  isZoomable = false,
  selectedFormat,
  alt,
  aspectRatio,
  sx = {}
}: ImageStrapiProps) => {
  const { getImageSrc } = useImageFormats();
  const strapiImageSrc = strapiImage?.data ? getImageSrc(strapiImage, selectedFormat) : undefined;
  if (!strapiImageSrc) return null;

  if (!isZoomable) {
    return <Image src={strapiImageSrc} alt={alt ?? ''} aspectRatio={aspectRatio} sx={{ width: '100%', ...sx }} />;
  }

  return (
    <Box
      sx={{
        position: 'relative'
      }}
    >
      <ZoomBox isZoomable={isZoomable} strapiImage={strapiImage}></ZoomBox>
      <Image src={strapiImageSrc} alt={alt ?? ''} aspectRatio={aspectRatio} sx={{ width: '100%', ...sx }} />
    </Box>
  );
};

const ZoomBox = ({ isZoomable, strapiImage }: { isZoomable?: boolean; strapiImage?: StrapiImage }) => {
  const { getImageSrc } = useImageFormats();
  const [open, setOpen] = useState(false);
  if (!isZoomable) {
    return null;
  }

  const source = strapiImage ? getImageSrc(strapiImage, 'large', 2000) : undefined;

  return (
    <>
      <IconButton
        sx={{
          position: 'absolute',
          bottom: '1rem',
          right: '1rem'
        }}
        iconButtonType="light"
        onClick={() => setOpen(true)}
      >
        <IconSearch />
      </IconButton>
      <Lightbox
        open={open}
        plugins={[Zoom]}
        zoom={{
          scrollToZoom: true,
          maxZoomPixelRatio: 5
        }}
        close={() => setOpen(false)}
        render={{
          buttonPrev: () => null,
          buttonNext: () => null
        }}
        slides={[{ src: source ?? '' }]}
      />
    </>
  );
};
