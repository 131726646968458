export type ParcoursStatus =
  | 'course_20_to_begin' // avec le niveau
  // progress à activer dès qu'on clique sur le bouton démarrer (1, 1)
  | 'course_30_progress'
  | 'course_40_finished_lessons'
  | 'course_50_failed_test'
  | 'course_60_succeeded_test';

export type DatabaseStatus = 'course_10_orientation' | ParcoursStatus;

export const COURSE_10_ORIENTATION = 'course_10_orientation';
export const COURSE_20_TO_BEGIN = 'course_20_to_begin';
export const COURSE_30_PROGRESS = 'course_30_progress';
export const COURSE_40_FINISH_LESSONS = 'course_40_finished_lessons';
export const COURSE_50_FAILED_TEST = 'course_50_failed_test';
export const COURSE_60_SUCCEEDED_TEST = 'course_60_succeeded_test';
