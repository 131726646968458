import { useTranslation } from 'react-i18next';
import { ConditionnalPage } from '../components/atoms/ConditionnalPageContainer';
import { ErrorOrSuccessBlock } from '../components/molecules/ErrorOrSuccessBlock';
import { usePageViewReport } from '../hooks/analytic/usePageViewReport';
import { useProfile } from '../hooks/useProfile';
import { localizeUrl } from '../utils/tools';

export const NotFoundPage = ({ isPage = true }: ConditionnalPage) => {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  usePageViewReport({ language, pageType: 'erreur', pageName: t('pageNotFound.title') });

  const { isLoggedIn } = useProfile();
  return (
    <ErrorOrSuccessBlock
      title={t('pageNotFound.title')}
      text={t('pageNotFound.text')}
      buttonLabel={t('pageNotFound.goToHomepage')}
      isContainedButton={true}
      to={localizeUrl('/', isLoggedIn)}
      isPage={isPage}
    />
  );
};

export default NotFoundPage;
