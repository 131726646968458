import { Box, SxProps } from '@mui/material';

type ImageProps = {
  src: string;
  alt?: string;
  sx?: SxProps;
  /**
   * Aspect ratio in percentage
   * X : width, Y: height
   * X:Y = X / Y
   * 16:9 = 56.25%
   * 4:3 = 75%
   */
  aspectRatio?: string;
};

export const Image = ({ src, alt, aspectRatio, sx = {} }: ImageProps) =>
  aspectRatio ? (
    <Box
      sx={{
        overflow: 'hidden',
        width: '100%',
        ...sx
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          pb: aspectRatio
        }}
      >
        <Box
          component="img"
          src={src}
          alt={alt}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            ...sx
          }}
        />
      </Box>
    </Box>
  ) : (
    <Box component="img" src={src} alt={alt} sx={sx} />
  );
