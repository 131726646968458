import { Box } from '@mui/material';
import { useState } from 'react';
import { HEADER_LINK_STYLE } from '../../../constants/layout';
import { LinkOrMenu } from '../../../utils/layout';
import { Button } from '../../atoms/Button';
import { IconChevron } from '../../icons/IconChevron';

export type HeaderLinksMobileProps = {
  links: LinkOrMenu[];
  onClick: () => void;
};

export const HeaderLinksMobile = ({ links, onClick }: HeaderLinksMobileProps) => {
  const [activeItem, setActiveItem] = useState<number | undefined>();

  return (
    <Box
      role="list"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        rowGap: '2.5rem'
      }}
    >
      {links.map(({ label, to, href, subLinks }, index) => {
        const isOpen = activeItem === index;
        const hasSubLinks = subLinks && subLinks.length !== 0;
        return (
          <Box key={label}>
            <Button
              role="listitem"
              key={label}
              to={to}
              href={href}
              onClick={() => {
                // Reset active item
                setActiveItem(undefined);

                // If submenu and not active, show sublinks
                if (hasSubLinks && !isOpen) {
                  setActiveItem(index);
                }
                // If link, close menu
                else if (!hasSubLinks) {
                  onClick();
                }
              }}
              endIcon={subLinks && <IconChevron orientation={isOpen ? 'top' : 'bottom'} />}
              sx={{ ...HEADER_LINK_STYLE }}
            >
              {label}
            </Button>
            {isOpen && (
              <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '1.75rem', mt: '1.75rem', pl: '1rem' }}>
                {subLinks?.map((subLink) => (
                  <Button
                    key={subLink.label}
                    to={subLink.to}
                    href={subLink.href}
                    onClick={onClick}
                    sx={{ ...HEADER_LINK_STYLE, color: '#000' }}
                  >
                    {subLink.label}
                  </Button>
                ))}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
