import { Box, SxProps } from '@mui/material';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../../constants/layout';

type PageProps = React.PropsWithChildren<{
  sx?: SxProps;
}>;

export const Page = ({ sx = {}, children }: PageProps) => (
  <Box
    sx={{
      minHeight: { xs: 'none', md: `calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT})` },
      pt: { xs: '1.5rem', sm: '3rem' },
      pb: '6rem',
      ...sx
    }}
  >
    {children}
  </Box>
);
