import { ModelName } from '../models/routes';

// User
const USER_URL = `${process.env.REACT_APP_USER_URL ?? '/api'}${
  process.env.REACT_APP_USE_MOCK === 'true' ? '/mock' : ''
}`;
export const LOGIN = `${USER_URL}/login`;
export const LOGOUT = `${USER_URL}/logout`;

// Public
export const NON_DISPONIBLE = 'non-disponible';
export const ERREUR_TECHNIQUE = 'erreur';
export const FORMULAIRE_INSCRIPTION = 'inscription';
export const GENERIC_CONFIRMATION_PAGE = 'confirmation';
export const HOMEPAGE_EMB = 'emb';
export const ARTICLE_EMB = 'article-emb';
export const PAGE_PUBLIQUES = 'page-publique';

// Private
export const ESPACE_PRIVE = 'espace-prive';
export const PAGE_PRIVEES = 'page-privee';
export const PARCOURS_FORMATION = 'parcours-formation';
export const PARCOURS_FORMATION_RESULT = 'resultat';
export const BIBLIOTHEQUE_SAVOIR = 'bibliotheque-savoir';
export const BRIQUE_DE_SAVOIR = 'brique-de-savoir';
export const TEST_ORIENTATION = 'test-orientation';
export const TEST_ORIENTATION_RESULT = 'resultat';
export const HOMEPAGE_MEDEIS = 'medeis';
export const ARTICLE_MEDEIS = 'article-medeis';
export const PROFIL = 'mon-profil';
export const PROFIL_PROFIL = 'profil';
export const PROFIL_FAVORIS = 'favoris';
export const PROFIL_INFOS_PERSO = 'informations-personnelles';
export const PROFIL_CONTACTER_PILEJE = 'contacter-pileje';
export const EVENEMENTS = 'evenements';
export const PREVIEW = 'preview';

export const ROUTE_MODEL_MAP: { [key: string]: ModelName } = {};
ROUTE_MODEL_MAP[PARCOURS_FORMATION] = 'parcoursFormations';
ROUTE_MODEL_MAP[BRIQUE_DE_SAVOIR] = 'briqueSavoirs';
ROUTE_MODEL_MAP[TEST_ORIENTATION] = 'quizs';
ROUTE_MODEL_MAP[PAGE_PRIVEES] = 'pagePrivees';
ROUTE_MODEL_MAP[PAGE_PUBLIQUES] = 'pagePubliques';
ROUTE_MODEL_MAP[EVENEMENTS] = 'evenements';
