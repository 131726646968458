import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';

const cache = new InMemoryCache({
  possibleTypes: {
    DynamicZone: [
      'ComponentCookiesOptOut',
      'ComponentGeneriqueImage',
      'ComponentGeneriqueImageSansLegende',
      'ComponentGeneriqueImageTexte',
      'ComponentGeneriqueTexte',
      'ComponentGeneriqueVideo',
      'ComponentGeneriqueVideoTexte',
      'ComponentGeneriqueCitation',
      'ComponentGeneriqueLien',
      'ComponentGeneriqueDocument',
      'ComponentGeneriqueTuiles',
      'ComponentGeneriqueCertification',
      'ComponentQuizQuestion',
      'ComponentGeneriqueCommunaute'
    ]
  }
});

const authenticatedCmsLink = new HttpLink({
  uri: `${process.env.REACT_APP_USER_URL ?? '/api'}/cms/authenticated/graphql`
});
const publicCmsLink = new HttpLink({
  uri: `${process.env.REACT_APP_USER_URL ?? '/api'}/cms/public/graphql`
});
const cmsLink = ApolloLink.split((operation) => !!operation.getContext().isPublic, publicCmsLink, authenticatedCmsLink);
const userLink = new HttpLink({
  uri: `${process.env.REACT_APP_USER_URL ?? '/api'}/user/graphql`,
  credentials: process.env.REACT_APP_DISABLE_CORS ? 'include' : 'same-origin'
});
const link = ApolloLink.split((operation) => !!operation.getContext().isUserQuery, userLink, cmsLink);

export const GraphQLClient = new ApolloClient({
  cache,
  link
});
