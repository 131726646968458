import { QuizAnswerAnalytics } from '../components/molecules/quiz/Quiz';
import { PARCOURS_FORMATION_MINIMAL_SCORE } from '../constants/quiz';
import { COURSE_60_SUCCEEDED_TEST } from '../constants/user-data';
import { UserQuizzAnswerByThematic, UserSuggestion } from '../models/user';
import { getQuizScore } from './quiz';

type AnswersByThematicByLevel = {
  thematic: string;
  datas: {
    level: number;
    type: string;
    answer: QuizAnswerAnalytics[];
  }[];
}[];

const suggestLevel = (datas: { level: number; type: string; answer: QuizAnswerAnalytics[] }[]) => {
  if (datas.length === 2) {
    const succeededParcours = datas.filter((data) => {
      return data.type === COURSE_60_SUCCEEDED_TEST;
    });
    if (succeededParcours.length === 0) return 0;
    // all parcours are complete, we suggest level 2 to avoid null value
    if (succeededParcours.length === 2) return 1;
    // if only one is complete we suggest the other one
    return (succeededParcours[0].level + 1) % 2;
  }
  if (datas.length === 1) {
    // if parcours succeeded, then suggest other one
    if (datas[0].type === COURSE_60_SUCCEEDED_TEST) return (datas[0].level + 1) % 2;
    // otherwise suggest the one suggested by orientation test
    const quizScore = getQuizScore(datas[0].answer);
    const isQuizSuccess = quizScore > PARCOURS_FORMATION_MINIMAL_SCORE;
    return isQuizSuccess ? 1 : 0;
  }
  // in case nothing match ?? suggest first level
  return 0;
};

export const buildSuggestions = (userQuizzAnswers: UserQuizzAnswerByThematic[]): UserSuggestion[] => {
  const answersByThematicByLevel: AnswersByThematicByLevel = [];

  userQuizzAnswers.forEach((answer) => {
    // Check if the thematic already exists
    const thematicEntry = answersByThematicByLevel.find((entry) => entry.thematic === answer.thematic);

    if (thematicEntry) {
      // Check if the level already exists in the thematic's datas
      const levelEntry = thematicEntry.datas.find((data) => data.level === answer.level);

      if (levelEntry) {
        levelEntry.type = answer.type;
        levelEntry.answer = answer.answers;
      } else {
        thematicEntry.datas.push({
          level: answer.level,
          type: answer.type,
          answer: answer.answers
        });
      }
    } else {
      answersByThematicByLevel.push({
        thematic: answer.thematic,
        datas: [
          {
            level: answer.level,
            type: answer.type,
            answer: answer.answers
          }
        ]
      });
    }
  });

  return answersByThematicByLevel.map((thematic) => ({
    thematic: thematic.thematic,
    suggestedLevel: suggestLevel(thematic.datas)
  }));
};
