import { SvgIcon } from '@mui/material';

export const IconLock = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 9.75L5 9.75C4.86193 9.75 4.75 9.86193 4.75 10L4.75 20C4.75 20.1381 4.86193 20.25 5 20.25L19 20.25C19.1381 20.25 19.25 20.1381 19.25 20L19.25 10C19.25 9.86193 19.1381 9.75 19 9.75ZM5 8.25L19 8.25C19.9665 8.25 20.75 9.0335 20.75 10V20C20.75 20.9665 19.9665 21.75 19 21.75L5 21.75C4.0335 21.75 3.25 20.9665 3.25 20L3.25 10C3.25 9.0335 4.0335 8.25 5 8.25Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.75C10.7574 3.75 9.75 4.75736 9.75 6L9.75 8.25L14.25 8.25L14.25 6C14.25 4.75736 13.2426 3.75 12 3.75ZM8.25 6C8.25 3.92893 9.92893 2.25 12 2.25C14.0711 2.25 15.75 3.92893 15.75 6L15.75 8.76923C15.75 9.3109 15.3109 9.75 14.7692 9.75L9.23077 9.75C8.6891 9.75 8.25 9.31089 8.25 8.76923L8.25 6Z"
    />
  </SvgIcon>
);
