import { Box, Typography } from '@mui/material';
import { HTMLAttributeAnchorTarget, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SideBarDataProps } from '../../../constants/mon-profil';
import { LOGOUT, PROFIL } from '../../../constants/routing';
import { localizeUrl } from '../../../utils/tools';
import { Button } from '../../atoms/Button';
import { IconLogout } from '../../icons/IconLogout';

export type SideBarLinkProps = {
  label: string;
  icon: JSX.Element;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  slug?: string;
  isOpen?: boolean;
  onClick?: () => void;
};

const SideBarLink = ({ label, icon, href, target, slug, isOpen = false, onClick = () => void 0 }: SideBarLinkProps) => {
  return (
    <Button
      variant="text"
      startIcon={icon}
      href={href}
      target={target}
      to={slug && localizeUrl(`/${PROFIL}/${slug}`, true)}
      onClick={onClick}
      sx={{
        mb: '2.5rem',
        color: isOpen ? 'primary.main' : 'text.secondary',
        borderBottom: 1,
        borderRadius: 0,
        borderColor: isOpen ? 'primary.main' : 'transparent',
        '&:hover': {
          color: 'primary.main'
        }
      }}
    >
      <Typography variant="body1" sx={{ fontWeight: isOpen ? '700' : '400' }}>
        {label}
      </Typography>
    </Button>
  );
};

type SideBarLinksData = {
  sideBarLinksData: SideBarDataProps[];
};

export const SideBarLinks = ({ sideBarLinksData }: SideBarLinksData) => {
  const { t } = useTranslation();
  const initCurrentPageSlug = window.location.pathname.split('/').at(-1);
  const [currentPageSlug, setCurrentPageSlug] = useState<string>(initCurrentPageSlug || '');
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column'
      }}
    >
      {sideBarLinksData.map(({ label, icon, slug }) => {
        return (
          <SideBarLink
            key={slug}
            label={t(label)}
            icon={icon}
            slug={slug}
            isOpen={slug === currentPageSlug}
            onClick={() => {
              setCurrentPageSlug(slug);
            }}
          />
        );
      })}
      <SideBarLink label={t('header.logout')} icon={<IconLogout />} href={LOGOUT} target="_self" />
    </Box>
  );
};
