import { Box } from '@mui/material';
import { HEADER_DROPDOWN_MENU_ID, HEADER_HEIGHT, HEADER_LINK_STYLE } from '../../../constants/layout';
import { ReactLink } from '../../../models/common';
import { Button } from '../../atoms/Button';

type DropdownMenuProps = {
  dropdownLinks: ReactLink[];
  onClick: () => void;
};

export const DropdownMenu = ({ dropdownLinks, onClick }: DropdownMenuProps) => {
  return (
    <Box
      id={HEADER_DROPDOWN_MENU_ID}
      sx={{
        zIndex: 999,
        position: 'absolute',
        top: HEADER_HEIGHT,
        left: 0,
        width: '100%',
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'primary.main',
        backgroundColor: '#fff',
        boxShadow: '0 0 20px rgba(0,0,0,0.15)'
      }}
    >
      <Box
        role="list"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          columnGap: '5rem',
          py: '2rem'
        }}
      >
        {dropdownLinks.map((dropdownLink) => {
          return (
            <Button
              key={dropdownLink.label}
              role="listitem"
              to={dropdownLink.to}
              href={dropdownLink.href}
              onClick={onClick}
              sx={HEADER_LINK_STYLE}
            >
              {dropdownLink.label}
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};
