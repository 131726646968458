import { SvgIcon } from '@mui/material';

export const IconStar = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1914 3.92993C11.6095 3.02336 12.898 3.02336 13.3162 3.92993L15.3657 8.37327L20.2249 8.94941C21.2163 9.06696 21.6145 10.2924 20.8815 10.9702L17.2889 14.2925L18.2426 19.0919C18.4372 20.0711 17.3948 20.8285 16.5236 20.3408L12.2538 17.9507L7.98395 20.3408C7.11278 20.8285 6.07039 20.0711 6.26496 19.0919L7.2186 14.2925L3.62606 10.9702C2.89308 10.2924 3.29124 9.06696 4.28266 8.94941L9.14186 8.37327L11.1914 3.92993ZM9.32483 8.35158L9.32532 8.35152L9.36418 8.67926L9.32532 8.35152C9.32516 8.35154 9.325 8.35156 9.32483 8.35158ZM17.1533 14.4179L17.1538 14.4174ZM15.1822 8.35152C15.1824 8.35154 15.1825 8.35156 15.1827 8.35158ZM12.2538 5.20793L10.4266 9.1693C10.2561 9.53878 9.90599 9.79318 9.50193 9.84109L5.16981 10.3547L8.37266 13.3166L7.91405 13.8125L8.37266 13.3166C8.67139 13.5929 8.80514 14.0045 8.72584 14.4036L7.87564 18.6824L11.6823 16.5516C12.0374 16.3529 12.4702 16.3529 12.8252 16.5516L16.6319 18.6824L15.7817 14.4036C15.7024 14.0045 15.8362 13.5929 16.1349 13.3166L19.3377 10.3547L15.0056 9.84109C14.6016 9.79318 14.2514 9.53878 14.081 9.1693L12.2538 5.20793Z"
    />
  </SvgIcon>
);
