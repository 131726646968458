import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SIDEBAR_LINKS_DATA } from '../../../constants/mon-profil';
import { IconButton } from '../../atoms/IconButton';
import { IconArrow } from '../../icons/IconArrow';
import { IconUser } from '../../icons/IconUser';
import { SideBarLinks } from '../mon-profil/SideBarLinks';

type UserBurgerMenuProps = {
  onMenuClose: () => void;
};

export const UserBurgerMenu = ({ onMenuClose }: UserBurgerMenuProps) => {
  const { t } = useTranslation();
  const closeLabel = t('menu.close');
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: '4rem'
        }}
      >
        <IconButton
          aria-label={closeLabel}
          onClick={onMenuClose}
          sx={{
            svg: { color: 'primary.main' }
          }}
        >
          <IconArrow orientation="left" />
        </IconButton>
        <IconButton
          aria-hidden
          iconButtonType="light"
          disabled={true}
          sx={{
            ml: '0.5rem'
          }}
        >
          <IconUser />
        </IconButton>
      </Box>
      <Box sx={{ pl: '1rem' }}>
        <SideBarLinks sideBarLinksData={SIDEBAR_LINKS_DATA} />
      </Box>
    </Box>
  );
};
