import { HTMLAttributeAnchorTarget } from 'react';
import i18n from '../config/i18next';
import { ESPACE_PRIVE } from '../constants/routing';
import { ReactLink, StrapiLink } from '../models/common';

/**
 * Return link path with locale
 * @param {string} path
 * @param {boolean} isPrivate
 * @returns {string}
 */
export const localizeUrl: (path: string, isPrivate?: boolean) => string = (path, isPrivate = false) => {
  const privatePath = isPrivate ? `/${ESPACE_PRIVE}` : '';
  return `/${i18n.resolvedLanguage || 'fr'}${privatePath}${path}`;
};

/**
 * Transform an array of StrapiLinks into ReactLinks
 * @param {StrapiLink[]} strapiLinks
 * @returns {ReactLink[]}
 */
export const transformLinks = (strapiLinks: StrapiLink[]): ReactLink[] =>
  strapiLinks.map((strapiLink) => {
    return transformLink(strapiLink);
  });

/**
 * Transform StrapiLink into ReactLink
 * @param {StrapiLink} strapiLink
 * @returns {ReactLink}
 */
export const transformLink = (strapiLink: StrapiLink): ReactLink => {
  const isHref = strapiLink.Lien.includes('http');
  return {
    label: strapiLink.Label,
    to: !isHref ? localizeUrl(strapiLink.Lien) : undefined,
    href: isHref ? strapiLink.Lien : undefined
  };
};

/**
 * Get image src from strapi image url
 * @param {string} url
 * @returns {string}
 */
export const getStrapiMediaSrc = (url: string): string => {
  return process.env.REACT_APP_S3_DISABLED === 'true'
    ? `${process.env.REACT_APP_BACKEND_URL || 'http://127.0.0.1:1337'}${url}`
    : url;
};

/**
 * Transforme a list of strings (ex: ['Marcel', 'Odette', 'André' ])
 * into a new string joined with commas and a final joining word (ex: 'and')
 * (ex: 'Marcel, Odette and André')
 * @param words: list of strings
 * @param and: final joining word : "et" (fr) ou "and" (en)
 */
export const buildListString = (strings: string[], and: string): string => {
  const length = strings.length;
  if (length === 0) return '';
  if (length === 1) return strings[0];
  return `${strings.slice(0, -1).join(', ')} ${and} ${strings[length - 1]}`;
};

/**
 * Returns today's date as a string
 */
export const getTodayDate = (): string => {
  return new Date().toISOString().slice(0, 10);
};

/**
 * Get adequate target for link
 */
const getLinkTarget = (
  isLinkExternal: boolean,
  target: HTMLAttributeAnchorTarget | undefined
): HTMLAttributeAnchorTarget | undefined => {
  if (target) return target;
  if (isLinkExternal) return '_blank';
  return undefined;
};
/**
 * Get adequate attributes (target & rel) for a link
 */
export const getLinkAttributes = (
  isLinkExternal: boolean,
  forcedTarget: HTMLAttributeAnchorTarget | undefined
): { target?: HTMLAttributeAnchorTarget; rel?: string } => {
  const target = getLinkTarget(isLinkExternal, forcedTarget);
  return { target, rel: target === '_blank' ? 'noopener noreferrer' : undefined };
};

type Debounce = <T extends unknown[]>(callback: (...args: T) => void, delay?: number) => (...args: T) => void;

export const debounce: Debounce = (callback, delay = 2000) => {
  let id: NodeJS.Timeout;
  return (...args) => {
    clearTimeout(id);
    id = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};
