import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { SeoAttributes } from '../../models/seo';
import { addAnalytic, AnalyticData, PageViewData } from './utils';

export type PageView = {
  pageName?: string | null;
  pageType: string;
  language: string;
  attributes?: SeoAttributes['Seo'];
};

export const usePageViewReport = ({ pageName, pageType, language, attributes }: PageView): void => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (!pageName) return;
    const page: PageViewData['page'] = {
      path: pathname,
      pagename: pageName,
      pagetype: pageType,
      language
    };

    const fullPath = pathname;
    let breadcrumbs;
    const dataLayer = attributes?.DataLayer;

    // en fonction du pagetype on peut avoir des chapters custom
    switch (pageType) {
      case 'mon_compte':
        page.page_chapter1 = 'account';
        break;
      case 'evenement':
        page.page_chapter1 = 'agenda';
        break;
      default:
        /* si un datalayer est définit cela veut dire que le client veux des valeurs customs pour la page */
        if (dataLayer) {
          breadcrumbs = dataLayer.split('/').filter((crumb) => crumb !== '');
        } else {
          // on enlève la langue du breadcrumb
          breadcrumbs = fullPath
            .split('/')
            .filter((crumb) => crumb !== '')
            .slice(1);
        }

        // special case for espace-prive
        if (breadcrumbs[0] === 'espace-prive') {
          breadcrumbs.shift();
        }

        breadcrumbs.forEach((crumb, index) => {
          page[`page_chapter${index + 1}`] = crumb;
        });
        break;
    }

    const data: AnalyticData = {
      event: 'pageview',
      page
    };
    addAnalytic(data);
  }, [pageName]);
};
