import { Breakpoint } from '@mui/material';
import { ImageFormatAttributes, StrapiImage } from '../models/common';
import { StrapiImageFormat } from '../models/image';
import { getStrapiMediaSrc } from '../utils/tools';
import { useViewport } from './useViewport';

export const useImageFormats = (breakpoint?: Breakpoint) => {
  const { width: viewportWidth } = useViewport(breakpoint);

  const getImageSrc = (strapiImage: StrapiImage, selectedFormat?: StrapiImageFormat, width: number | null = null) => {
    const { url: rawUrl, formats } = strapiImage.data?.attributes || {};
    if (!rawUrl) {
      throw new Error('useImageFormats: getImageSrc: missing url');
    }

    // en cas d'image très petite
    if (!formats) return getStrapiMediaSrc(rawUrl);
    // with to use override if provided
    const withToUse = width || viewportWidth;

    // on renvoie le format sélectionné si width <= viewportWidth
    if (selectedFormat) {
      const format = formats[selectedFormat];
      if (format && format.width <= withToUse) return getStrapiMediaSrc(format.url);
    }

    // on choisit le plus grand format avec width <= viewportWidth
    let maxAllowedFormat: ImageFormatAttributes | undefined;
    Object.keys(formats).forEach((key) => {
      const format = formats[key as StrapiImageFormat];
      const { width } = format;
      if ((!maxAllowedFormat || maxAllowedFormat.width < width) && width <= withToUse) {
        maxAllowedFormat = format;
      }
    });

    return getStrapiMediaSrc(maxAllowedFormat ? maxAllowedFormat.url : rawUrl);
  };

  return {
    getImageSrc
  };
};
