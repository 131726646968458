import { IconChat } from '../components/icons/IconChat';
import { IconLock } from '../components/icons/IconLock';
import { IconStar } from '../components/icons/IconStar';
import { IconUser } from '../components/icons/IconUser';
import { PROFIL_CONTACTER_PILEJE, PROFIL_FAVORIS, PROFIL_INFOS_PERSO, PROFIL_PROFIL } from './routing';

export const BADGES_PARCOURS_SAVOIR_FAIRE_QUERY_LIMIT = 50;

export type SideBarDataProps = {
  label: string;
  icon: JSX.Element;
  slug: string;
};

export const SIDEBAR_LINKS_DATA: SideBarDataProps[] = [
  {
    label: 'header.link.profile',
    icon: <IconUser />,
    slug: PROFIL_PROFIL
  },
  {
    label: 'header.link.fav',
    icon: <IconStar />,
    slug: PROFIL_FAVORIS
  },
  {
    label: 'header.link.personal-info',
    icon: <IconLock />,
    slug: PROFIL_INFOS_PERSO
  },
  {
    label: 'header.link.contact',
    icon: <IconChat />,
    slug: PROFIL_CONTACTER_PILEJE
  }
];
