import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translations.json';
import translationFR from '../locales/fr/translations.json';

type Locale = {
  strapi: string;
  translation: { [key: string]: string };
};
type Locales = { [key: string]: Locale };
type Resource = {
  translation: { [key: string]: string };
};
type Resources = { [key: string]: Resource };

/**
 * The first locale is the default one,
 * which is the language used when no locale is set in url.
 * */
const locales: Locales = {
  fr: {
    strapi: 'fr',
    translation: translationFR
  },
  en: {
    strapi: 'en',
    translation: translationEN
  }
};

export const localeKeys = Object.keys(locales);
export const getStrapiLocale = (localeKey: string) => locales[localeKey].strapi;

const resources: Resources = {};
Object.keys(locales).forEach((key) => {
  resources[key] = { translation: locales[key].translation };
});

//eslint-disable-next-line  @typescript-eslint/no-floating-promises
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: localeKeys[0],
    debug: false,
    ns: ['translation'],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
