import { gql, useQuery } from '@apollo/client';
import { createContext, useMemo, useState } from 'react';
import { UserFavorite, UserParcoursFormation, UserProfile, UserQuery, UserSuggestion } from '../models/user';
import { buildSuggestions } from '../utils/suggestions';

export type UserValues = {
  profile?: UserProfile;
  progress: UserParcoursFormation[];
  suggestions: UserSuggestion[];
  favorites: UserFavorite[];
};

export type setProgressArgs = (args: UserParcoursFormation[]) => void;
export type setSuggestionArgs = (args: UserSuggestion[]) => void;
export type setFavoritesArgs = (args: UserFavorite[]) => void;

export type UserCallbacks = {
  setProgress: setProgressArgs;
  setSuggestions: setSuggestionArgs;
  setFavorites: setFavoritesArgs;
};

export const UserContext = createContext<[UserValues, UserCallbacks]>([
  { progress: [], suggestions: [], favorites: [] },
  {
    setProgress: () => null,
    setSuggestions: () => null,
    setFavorites: () => null
  }
]);

export const UserContextProvider = ({ children }: React.PropsWithChildren) => {
  const [profile, setProfile] = useState<UserProfile | undefined>(undefined);
  const [progress, setProgress] = useState<UserParcoursFormation[]>([]);
  const [suggestions, setSuggestions] = useState<UserSuggestion[]>([]);
  const [favorites, setFavorites] = useState<UserFavorite[]>([]);
  const userContextValue = useMemo<[UserValues, UserCallbacks]>(
    () => [
      { profile, progress, suggestions, favorites },
      { setProgress, setSuggestions, setFavorites }
    ],
    [profile, progress, suggestions, favorites, setProgress, setSuggestions, setFavorites]
  );

  useQuery<UserQuery>(USER_QUERY, {
    context: { isUserQuery: true },
    errorPolicy: 'ignore',
    onCompleted: (data) => {
      setProfile(data.getUserProfile);
      setProgress(data.getUserProgress ?? []);
      setSuggestions(buildSuggestions(data.getUserSavoirQuizzAnswers ?? []));
      setFavorites(data.getUserFavorites ?? []);
    },
    onError: (error) => {
      if (!error.message.includes('User not authenticated')) {
        console.error('UserContext: %o', error);
      }
      setProfile({});
    }
  });

  return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>;
};

const USER_QUERY = gql`
  query User_GetUserData {
    getUserProgress {
      parcoursId
      status
      pourcentage
      progress {
        objectif
        briqueSavoir
        capsule
      }
      thematique {
        thematiqueSlug
        niveau
      }
    }
    getUserProfile {
      userId
      firstname
      lastname
      login
      roles
      country
    }
    getUserFavorites {
      contentType
      contentSlug
      expirationDate
    }
    getUserSavoirQuizzAnswers {
      thematic
      type
      level
      answers {
        question
        selectedAnswerIndex
        selectedAnswer
      }
    }
  }
`;
