import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HEADER_HEIGHT } from '../../../constants/layout';
import { LinkOrMenu } from '../../../utils/layout';
import { localizeUrl } from '../../../utils/tools';
import { IconButton } from '../../atoms/IconButton';
import { Image } from '../../atoms/Image';
import { LanguageSelector } from '../../atoms/LanguageSelector';
import { Link } from '../../atoms/Link';
import { IconClose } from '../../icons/IconClose';
import { Container } from '../../layouts/Container';
import { HeaderLinksMobile } from './HeaderLinksMobile';
import { LoggedOutButtons } from './LoggedOutButtons';

type MainBurgerMenuProps = {
  headerLinks: LinkOrMenu[];
  isLoggedIn?: boolean;
  onMenuClose: () => void;
  locale: string;
};

export const MainBurgerMenu = ({ headerLinks, isLoggedIn, onMenuClose, locale }: MainBurgerMenuProps) => {
  const { t } = useTranslation();
  const ariaLabelMenuClose = t('menu.close');
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: HEADER_HEIGHT,
          width: '100%',
          mb: '2rem'
        }}
      >
        <Box sx={{ flex: 1 }} />
        <Box sx={{ flex: '0 1 7rem', display: 'flex', justifyContent: 'center' }}>
          <Link to={localizeUrl('/', isLoggedIn)} sx={{ display: 'inline-block', lineHeight: 0 }}>
            <Image src="/images/logo.png" alt="logo" sx={{ height: '4rem' }} />
          </Link>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            aria-label={ariaLabelMenuClose}
            onClick={onMenuClose}
            sx={{
              svg: {
                color: 'primary.main'
              },
              '.MuiSvgIcon-root': {
                height: '2rem',
                width: '2rem'
              }
            }}
          >
            <IconClose />
          </IconButton>
        </Box>
      </Box>

      {/* Links */}
      <Box sx={{ width: '100%', maxWidth: '18rem' }}>
        <HeaderLinksMobile links={headerLinks} onClick={onMenuClose} />

        {/* Lang, SignUp, SignIn */}
        <Box sx={{ mt: '2.5rem', mb: '3.5rem' }}>
          <LanguageSelector locale={locale} />
        </Box>
        {!isLoggedIn && (
          <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
            <LoggedOutButtons isSignupFirst />
          </Box>
        )}
      </Box>
    </Container>
  );
};
