import { QuizAnswer, QuizAnswerAnalytics } from '../components/molecules/quiz/Quiz';

/**
 * Randomize answers order
 * @param {QuizAnswer[]} array
 * @returns {QuizAnswer[]}
 */
export const shuffleQuizAnswers = (array: QuizAnswer[]): QuizAnswer[] => {
  // Use 0.5 to oscillate between positive and negative value
  return array.sort(() => 0.5 - Math.random());
};

/**
 * Get quiz end score in percentage
 * @param {QuizAnswerAnalytics[]} answers
 * @returns {number} between 0 and 100
 */
export const getQuizScore = (answers?: QuizAnswerAnalytics[]): number => {
  if (!answers) return 0;
  const questionsNumber = answers.length;
  const goodAnswersNumber = answers.filter(({ selectedAnswerIndex }) => selectedAnswerIndex === 1).length;
  return Math.floor((goodAnswersNumber / questionsNumber) * 100);
};
