import { useEffect } from 'react';
import { addAnalytic, AnalyticData } from './utils';

type Props = {
  event: string;
};
export const useSocialReport = ({ event }: Props): void => {
  useEffect(() => {
    if (!event) {
      return;
    }
    let data: AnalyticData;
    switch (event) {
      case 'social_facebook':
      case 'social_youtube':
      case 'social_linkedin':
        data = {
          event
        };
        break;
      default:
        throw new Error('Invalid event on social report');
    }

    addAnalytic(data);
  }, [event]);
};
