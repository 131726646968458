import { Box } from '@mui/material';
import { Image } from '../atoms/Image';

export const Loader = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column'
    }}
  >
    <Image
      src="/images/logo.png"
      alt="Pileje Factory"
      sx={{ margin: '2rem auto', marginTop: '5rem', height: '4rem' }}
    />
    <Image
      src="/images/loader.gif"
      alt="loading ..."
      sx={{
        margin: '0 auto',
        height: '5rem',
        width: '5rem'
      }}
    />
  </Box>
);
