import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { RoutePageProps } from '../App';
import { ErrorOrSuccessBlock } from '../components/molecules/ErrorOrSuccessBlock';
import { usePageViewReport } from '../hooks/analytic/usePageViewReport';

type PageErrorCases = 'unauthorized' | undefined;

export const PageError = ({ locale }: RoutePageProps) => {
  const { t } = useTranslation();
  usePageViewReport({ language: locale, pageType: 'erreur', pageName: t('pageError.title') });

  const [searchParams] = useSearchParams();
  const errorCase: PageErrorCases = searchParams.get('case') as PageErrorCases;

  return (
    <ErrorOrSuccessBlock
      isPage={true}
      title={errorCase ? t(`pageError.${errorCase}Title`) : undefined}
      text={errorCase ? t(`pageError.${errorCase}Text`) : undefined}
    />
  );
};

export default PageError;
