import { useContext } from 'react';
import { UserContext } from '../context/UserContextProvider';
import { UserProfile } from '../models/user';

type ProfileValues = {
  profile?: UserProfile;
  isLoggedIn: boolean | undefined;
};

export const useProfile = (): ProfileValues => {
  const [{ profile }] = useContext(UserContext);
  return {
    profile,
    isLoggedIn: profile && !!profile.userId
  };
};
