import { LazyExoticComponent, Suspense } from 'react';
import { RoutePageProps } from '../App';
import { PageLoading } from '../components/atoms/PageLoading';

export const withSuspense = (
  Component: LazyExoticComponent<({ locale, slug }: RoutePageProps) => JSX.Element>,
  { locale, slug }: RoutePageProps
): JSX.Element => (
  <Suspense fallback={<PageLoading />}>
    <Component locale={locale} slug={slug} />
  </Suspense>
);
