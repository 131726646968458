import { ButtonBaseProps, IconButton as MuiIconButton, IconButtonProps, SxProps } from '@mui/material';
import { ElementType, HTMLAttributeAnchorTarget } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import theme from '../../theme';
import { getLinkAttributes } from '../../utils/tools';

export const ICON_BUTTON_SIZES: { small: SxProps; medium: SxProps; large: SxProps } = {
  small: {
    height: '2rem',
    width: '2rem',
    '.MuiSvgIcon-root': {
      height: '1.25rem',
      width: '1.25rem'
    }
  },
  medium: {
    height: '2.75rem',
    width: '2.75rem',
    '.MuiSvgIcon-root': {
      height: '1.5rem',
      width: '1.5rem'
    }
  },
  large: {
    height: '3.5rem',
    width: '3.5rem',
    '.MuiSvgIcon-root': {
      height: '1.75rem',
      width: '1.75rem'
    }
  }
};

export const ICON_BUTTON_STYLES: { primary: SxProps; light: SxProps; white: SxProps } = {
  primary: {
    svg: {
      fill: 'white'
    }
  },
  light: {
    backgroundColor: theme.palette.primary.light,
    svg: {
      fill: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: theme.palette.background.paper
    },
    '&:disabled': {
      backgroundColor: theme.palette.primary.light,
      svg: {
        fill: theme.palette.primary.main
      }
    }
  },
  white: {
    backgroundColor: 'white',
    svg: {
      fill: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  }
};

type CustomIconButtonLinkProps = {
  to?: string;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
};

type CustomIconButtonProps = ButtonBaseProps &
  IconButtonProps &
  CustomIconButtonLinkProps & {
    iconButtonType?: keyof typeof ICON_BUTTON_STYLES;
  };

export const IconButton = ({
  iconButtonType = 'primary',
  size = 'medium',
  to,
  href,
  target,
  sx = {},
  ...props
}: CustomIconButtonProps) => {
  const iconButtonSx = {
    ...ICON_BUTTON_SIZES[size],
    ...ICON_BUTTON_STYLES[iconButtonType],
    ...sx
  };
  let iconButtonColor: IconButtonProps['color'];

  switch (iconButtonType) {
    case 'primary':
      iconButtonColor = 'primary';
      break;
    case 'light':
    case 'white':
      iconButtonColor = 'default';
      break;
  }

  // Allow IconButton to be used as RouterLink or HTML link
  let linkProps: CustomIconButtonLinkProps & { LinkComponent?: ElementType } = {};
  if (to) {
    linkProps = { to, LinkComponent: RouterLink };
  } else if (href) {
    linkProps = { href, LinkComponent: 'a', ...getLinkAttributes(false, target) };
  }

  return <MuiIconButton color={iconButtonColor} sx={iconButtonSx} {...linkProps} {...props} />;
};
