import { useLocation } from 'react-router';
import { ErrorOrSuccessBlock } from '../components/molecules/ErrorOrSuccessBlock';
import { NotFoundPage } from './NotFoundPage';

export const GenericConfirmationPage = () => {
  const location = useLocation();
  if (!location.state) return <NotFoundPage />;
  const { title, text, richText, buttonLabel, to } = location.state as {
    title: string;
    text?: string;
    richText?: string;
    buttonLabel: string;
    to?: string;
  };
  return <ErrorOrSuccessBlock title={title} text={text} buttonLabel={buttonLabel} to={to} richText={richText} />;
};

export default GenericConfirmationPage;
