import { gql, useLazyQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import { RoutePageProps } from '../../App';
import { ESPACE_PRIVE, LOGIN } from '../../constants/routing';
import { BASIC_IMAGE_GQL } from '../../graphql/ImageGql';
import { useProfile } from '../../hooks/useProfile';
import { DataWrapper } from '../../models/common';
import { StrapiEnTeteEntity, StrapiPiedPageEntity } from '../../models/layout';
import { Loader } from '../atoms/Loader';
import { ErrorOrSuccessBlock } from '../molecules/ErrorOrSuccessBlock';
import { Footer } from './Footer';
import { Header } from './Header';

type LayoutQuery = {
  enTeteConnecte?: DataWrapper<StrapiEnTeteEntity>;
  enTeteDeconnecte?: DataWrapper<StrapiEnTeteEntity>;
  piedPage: DataWrapper<StrapiPiedPageEntity>;
};

export const Layout = ({ locale }: RoutePageProps) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const isEspacePrive = ESPACE_PRIVE === location.pathname.split('/')[2];
  const { isLoggedIn } = useProfile();
  const [isLoading, setLoading] = useState(true);

  const [loadAuthenticatedLayout, { error: authenticatedLayoutError, data: authenticatedLayoutData }] =
    useLazyQuery<LayoutQuery>(AUTHENTICATED_LAYOUT_QUERY, {
      variables: { locale },
      onCompleted: () => {
        setLoading(false);
      }
    });

  const [loadPublicLayout, { error: publicLayoutError, data: publicLayoutData }] = useLazyQuery<LayoutQuery>(
    PUBLIC_LAYOUT_QUERY,
    {
      variables: { locale },
      context: { isPublic: true },
      onCompleted: () => {
        setLoading(false);
      }
    }
  );

  useEffect(() => {
    i18n.changeLanguage(locale).catch((err) => console.log('i18n.changeLanguage: %o', err));
  }, [locale]);

  useEffect(() => {
    if (isLoggedIn === undefined) {
      // profile loading
      return;
    }
    if (isEspacePrive && !isLoggedIn) {
      const urlEncoded = encodeURI(window.location.pathname);
      const loc = location.pathname.split('/')[1];
      window.location.assign(LOGIN + '?RelayState=' + urlEncoded + '&language=' + loc);
      return;
    }
    (isLoggedIn ? loadAuthenticatedLayout : loadPublicLayout)().catch(console.error);
  }, [isLoggedIn]);

  if (isLoading) return <Loader />;
  if (publicLayoutError || authenticatedLayoutError) return <ErrorOrSuccessBlock />;

  const enTete = isLoggedIn ? authenticatedLayoutData?.enTeteConnecte : publicLayoutData?.enTeteDeconnecte;
  const footer = isLoggedIn ? authenticatedLayoutData?.piedPage : publicLayoutData?.piedPage;

  return (
    <Box>
      <Header enTete={enTete?.data} isLoggedIn={isLoggedIn} locale={locale} />
      <Box component={'main'} sx={{ zIndex: 2 }}>
        <Outlet />
      </Box>
      <Footer piedPage={footer?.data} />
    </Box>
  );
};

const FOOTER_QUERY = `
  piedPage(locale: $locale) {
    data {
      attributes {
        Logo {
          ${BASIC_IMAGE_GQL}
        }
        LiensAGauche {
          Label
          Lien
        }
        LiensADroite {
          Label
          Lien
        }
        LiensReseauxSociaux {
          Label
          Lien
        }
        ScoreFruggr
      }
    }
  }
`;

const AUTHENTICATED_LAYOUT_QUERY = gql`
query GetLayoutConnecte($locale: I18NLocaleCode!) {
  enTeteConnecte(locale: $locale) {
    data {
      attributes {
        LienMenus {
          Label
          Lien
          SousMenus {
            Label
            Lien
          }
        }
      }
    }
  }
  ${FOOTER_QUERY}
}
`;

const PUBLIC_LAYOUT_QUERY = gql`
query GetLayoutDeconnecte($locale: I18NLocaleCode!) {
  enTeteDeconnecte(locale: $locale) {
    data {
      attributes {
        LienMenus {
          Label
          Lien
          SousMenus {
            Label
            Lien
          }
        }
      }
    }
  }
  ${FOOTER_QUERY}
}
`;
