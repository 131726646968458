import { Container } from '../layouts/Container';
import { Page } from './Page';

export type ConditionnalPage = {
  isPage?: boolean;
};
type ConditionnalPageContainerProps = React.PropsWithChildren & ConditionnalPage;

export const ConditionnalPageContainer = ({ isPage = true, children }: ConditionnalPageContainerProps) =>
  isPage ? (
    <Page>
      <Container>{children}</Container>
    </Page>
  ) : (
    <>{children}</>
  );
