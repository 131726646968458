import { Button as MuiButton, ButtonProps, SxProps } from '@mui/material';
import { HTMLAttributeAnchorTarget } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { getLinkAttributes } from '../../utils/tools';

// Style for all button types
const BUTTON_BASE_STYLE: SxProps = {
  borderWidth: '2px',
  '&:hover': {
    borderWidth: '2px'
  },
  'svg.MuiSvgIcon-root': {
    fontSize: '1.5rem'
  }
};

// Style per button types
const BUTTON_STYLES: { [key in NonNullable<ButtonProps['variant']>]: SxProps } = {
  contained: {
    py: '0.75rem',
    px: '1.25rem'
  },
  outlined: {
    py: '0.75rem',
    px: '1.25rem'
  },
  text: {
    py: '0.5rem',
    px: '0.5rem'
  }
};

type CustomButtonProps = ButtonProps & {
  to?: string;
  target?: HTMLAttributeAnchorTarget;
};

/**
 * Apply style properties depending on button color and button type
 */
const getButtonColorSx = (color: NonNullable<ButtonProps['color']>, variant: ButtonProps['variant']): SxProps => {
  if (variant === 'outlined' && color !== 'inherit') {
    return { borderColor: `${color}.main` };
  }
  return {};
};

export const Button = ({
  variant = 'text',
  color = 'primary',
  to,
  href,
  target,
  sx = {},
  ...props
}: CustomButtonProps) => {
  const buttonSx = {
    ...BUTTON_BASE_STYLE,
    ...getButtonColorSx(color, variant),
    ...BUTTON_STYLES[variant],
    ...sx
  };

  return (
    <MuiButton
      {...props}
      disableElevation
      component={to ? RouterLink : 'button'}
      to={to}
      href={href}
      variant={variant}
      sx={buttonSx}
      {...getLinkAttributes(!to, target)}
    />
  );
};
