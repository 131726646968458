import { useEffect } from 'react';
import { addAnalytic, AnalyticData } from './utils';

type Props = {
  userId: string;
  event: string;
};
export const useSignInReport = ({ event, userId }: Props): void => {
  useEffect(() => {
    if (!event) {
      return;
    }
    let data: AnalyticData;
    switch (event) {
      case 'security_sign_in_attempt':
      case 'security_sign_in_failure':
        data = {
          event
        };
        break;
      case 'security_sign_in_success':
        if (!userId) {
          throw new Error('Missing userId on signIn report');
        }
        data = {
          event,
          userId
        };
        break;
      default:
        throw new Error('Invalid event on signIn report');
    }

    addAnalytic(data);
  }, [event]);
};
