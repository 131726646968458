import { SvgIcon } from '@mui/material';

type IconArrowProps = { orientation?: 'right' | 'left' | 'top' | 'bottom' };

export const IconArrow = ({ orientation = 'right' }: IconArrowProps) => {
  let rotation = 'rotate(0deg)';
  switch (orientation) {
    case 'left':
      rotation = 'rotate(180deg)';
      break;
    case 'top':
      rotation = 'rotate(-90deg)';
      break;
    case 'bottom':
      rotation = 'rotate(90deg)';
      break;
    default:
      break;
  }
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" style={{ transform: rotation }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4285 5.89255L14.3806 4.99994L21.9999 12.1428L14.3806 19.2857L13.4285 18.393L20.0956 12.1428L13.4285 5.89255Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M2 11.5717H20.0952V13.0717H2V11.5717Z" />
    </SvgIcon>
  );
};
