import { DocumentNode, gql, OperationVariables, QueryResult, useLazyQuery } from '@apollo/client';
import { NativeSelect } from '@mui/material';
import { useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { localeKeys } from '../../config/i18next';
import { ESPACE_PRIVE, EVENEMENTS, NON_DISPONIBLE, PARCOURS_FORMATION, ROUTE_MODEL_MAP } from '../../constants/routing';
import { DataWrapper } from '../../models/common';
import { StrapiLocalizableEntity } from '../../models/layout';
import { ModelName } from '../../models/routes';
import { IconChevron } from '../icons/IconChevron';

type LanguageSelectorProps = { locale: string };
type Params = { slug: string };

type LanguageSelectorGqlMap = { [key: string]: DocumentNode };

type CollectionSlugQuery = {
  briqueSavoirs?: DataWrapper<StrapiLocalizableEntity[]>;
  parcoursFormations?: DataWrapper<StrapiLocalizableEntity[]>;
  quizs?: DataWrapper<StrapiLocalizableEntity[]>;
  pagePubliques?: DataWrapper<StrapiLocalizableEntity[]>;
  pagePrivees?: DataWrapper<StrapiLocalizableEntity[]>;
  evenements?: DataWrapper<StrapiLocalizableEntity[]>;
};

const translateSlug = (
  modelName: ModelName,
  newlocale: string,
  query: QueryResult<CollectionSlugQuery, OperationVariables>
) => {
  const actualCollection = query?.data?.[modelName]?.data?.[0];
  const associatedCollections = actualCollection?.attributes.localizations?.data ?? [];
  const translatedCollection = associatedCollections.find((col) => col.attributes.locale === newlocale);
  return translatedCollection?.attributes.Slug;
};

const getTypeOfContent = (pathArray: string[], isPublic: boolean) => {
  if (isPublic) return ROUTE_MODEL_MAP['page-publique'];
  if (pathArray[1] === EVENEMENTS) return EVENEMENTS;
  if (pathArray[1] === PARCOURS_FORMATION) return ROUTE_MODEL_MAP[pathArray[1]];
  return ROUTE_MODEL_MAP['page-privee'];
};

export const LanguageSelector = ({ locale }: LanguageSelectorProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams<Params>() || '';
  const pathArray = location.pathname.split('/').slice(2);
  const isPublic = pathArray[0] !== ESPACE_PRIVE;
  const type = getTypeOfContent(pathArray, isPublic);
  const queryGql = LANGUAGE_SELECTOR_QUERIES[type];
  const [loadSlugs] = useLazyQuery<CollectionSlugQuery>(queryGql, {
    variables: { locale, slug },
    context: { isPublic: isPublic }
  });

  const switchLanguage = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newlocale = event.target.value.toLowerCase();
      // Les pages "détail événement" ne sont pas localisées, inutile de traduire le slug.
      if (!slug || type === EVENEMENTS) {
        navigate(`/${newlocale}/${pathArray.join('/')}`);
        return;
      }

      loadSlugs()
        .then((query) => {
          const newSlug = translateSlug(type, newlocale, query);
          const newPath = newSlug ? `${!isPublic ? ESPACE_PRIVE + '/' : ''}${newSlug}` : NON_DISPONIBLE;
          navigate(`/${newlocale}/${newPath}`);
        })
        .catch((err) => {
          console.error('switchLanguage: %o', err);
          navigate(`/${newlocale}/${NON_DISPONIBLE}`);
        });
    },
    [slug, pathArray, navigate, loadSlugs]
  );
  return (
    <NativeSelect
      IconComponent={IconChevron}
      value={locale.toUpperCase()}
      onChange={switchLanguage}
      sx={{
        color: 'primary.main',
        fontWeight: 600,
        '&:before, &:focus:before': {
          display: 'none!important'
        },
        'select.MuiNativeSelect-select.MuiInput-input': {
          letterSpacing: '1px',
          pr: '1.75rem'
        },
        svg: {
          position: 'absolute',
          top: 0,
          right: 0,
          pointerEvents: 'none',
          height: '100%',
          width: '1.5rem'
        }
      }}
    >
      {localeKeys.map((lang) => (
        <option key={lang}>{lang.toUpperCase()}</option>
      ))}
    </NativeSelect>
  );
};

const LANGUAGE_SELECTOR_QUERIES: LanguageSelectorGqlMap = {};
Object.values(ROUTE_MODEL_MAP).forEach((modelName) => {
  LANGUAGE_SELECTOR_QUERIES[modelName] = gql`
    query GetLanguageSelector${modelName}($locale: I18NLocaleCode!, $slug: String!) {
      ${modelName}(locale: $locale, filters: { Slug: { eq: $slug } }) {
        data {
          attributes {
            Slug
            locale
            localizations {
              data {
                attributes {
                  locale
                  Slug
                }
              }
            }
          }
        }
      }
    }
  `;
});
