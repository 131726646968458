import { createContext, useEffect, useState } from 'react';

export const DidomiContext = createContext<boolean | undefined>(undefined);

// 'c:' suivi du vendorId (cf. ./public/index.html)
export const VIMEO_VENDOR_ID = 'c:vimeo';

export const DidomiContextProvider = ({ children }: React.PropsWithChildren) => {
  const [isVimeoConsent, setVimeoConsent] = useState<boolean | undefined>(false);

  useEffect(() => {
    // window.didomiOnReady is undefined during unit tests AND can be undefined is Didomi script was not executed yet.
    window.didomiOnReady = window.didomiOnReady ?? [];
    window.didomiOnReady.push(function (Didomi) {
      // La callback de subscribe est exécutée à chaque fois que
      // le statut (consentement) est modifié pour ce "vendeur"
      Didomi.getObservableOnUserConsentStatusForVendor(VIMEO_VENDOR_ID).subscribe(function (consentStatus) {
        setVimeoConsent(consentStatus);
      });
    });
  }, []);

  return <DidomiContext.Provider value={isVimeoConsent}>{children}</DidomiContext.Provider>;
};
