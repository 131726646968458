import { Box, Typography } from '@mui/material';
import parse, { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';
import { DynamicZoneChildProps, HasTypename, Identifiable } from '../../models/common';
import { RichTextStyleMap } from '../../models/dynamic-zone/componentGeneriqueTexte';

export type StrapiComponentGeneriqueTexte = HasTypename<'ComponentGeneriqueTexte'> &
  Identifiable & {
    Texte: string;
  };

export type ComponentGeneriqueTexteProps = DynamicZoneChildProps<StrapiComponentGeneriqueTexte>;

type SubComponentProps = {
  domNode: Element;
  options: HTMLReactParserOptions;
};

const RICHTEXT_STYLE_MAP: RichTextStyleMap = {
  h2: { variant: 'h2', sx: { mt: '2rem', mb: '1.5rem' } },
  h3: { variant: 'h3', sx: { mt: '1.5rem', mb: '1rem' } },
  p: { variant: 'body1', sx: { mb: '0.5rem' } },
  div: { variant: 'body2', sx: { fontStyle: 'italic', fontSize: '12px' } }
};

const RichTextComponent = (props: SubComponentProps) => {
  const { domNode, options } = props;
  const nodeStyle = RICHTEXT_STYLE_MAP[domNode.name];

  return (
    <Typography
      variant={nodeStyle.variant}
      sx={{
        ...nodeStyle.sx,
        // Override fontFamily style in span with copy/paste from Word or other editor
        span: { fontFamily: 'inherit !important' }
      }}
    >
      {domToReact(domNode.children, options)}
    </Typography>
  );
};

const FigureComponent = (props: SubComponentProps) => {
  const { domNode, options } = props;
  return (
    <Box tabIndex={0} sx={{ overflowX: 'auto', mb: '2rem' }}>
      {domToReact(domNode.children, options)}
    </Box>
  );
};

export const ComponentGeneriqueTexte = (props: ComponentGeneriqueTexteProps) => {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (!(domNode instanceof Element) || domNode.type !== 'tag') return null;
      if (Object.keys(RICHTEXT_STYLE_MAP).includes(domNode.name)) {
        return <RichTextComponent domNode={domNode} options={options} />;
      }
      if (domNode.name === 'figure') return <FigureComponent domNode={domNode} options={options} />;
    }
  };
  return !props.value.Texte ? null : (
    <Box
      sx={{
        '& > :first-of-type': { mt: 0 },
        '& > :last-of-type': { mb: 0 }
      }}
    >
      {parse(props.value.Texte, options)}
    </Box>
  );
};
