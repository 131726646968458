import { useEffect } from 'react';
import { addAnalytic, AnalyticData } from './utils';

type Props = {
  event: string;
  setFn: (event: string) => void;
};
export const useSignUpReport = ({ event, setFn }: Props): void => {
  useEffect(() => {
    if (!event) {
      return;
    }
    let data: AnalyticData;
    switch (event) {
      case 'security_sign_up_attempt':
      case 'security_sign_up_success':
        data = {
          event
        };
        break;
      default:
        throw new Error('Invalid event on signUp report');
    }

    addAnalytic(data);
    //reset event
    setFn('');
  }, [event]);
};
