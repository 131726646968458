import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useProfile } from '../../hooks/useProfile';
import { localizeUrl } from '../../utils/tools';
import { Button } from '../atoms/Button';
import { ConditionnalPage, ConditionnalPageContainer } from '../atoms/ConditionnalPageContainer';
import { ComponentGeneriqueTexte } from '../dynamic-zone/ComponentGeneriqueTexte';

type ErrorOrSuccessBlockProps = ConditionnalPage & {
  title?: string | null;
  text?: string | null;
  richText?: string;
  buttonLabel?: string | null;
  isContainedButton?: boolean;
  to?: string;
  onClick?: () => void;
};

export const ErrorOrSuccessBlock = (args: ErrorOrSuccessBlockProps) => {
  const { isLoggedIn } = useProfile();
  const { t } = useTranslation();
  const {
    title = t('pageError.title'),
    text = t('pageError.text'),
    richText,
    buttonLabel = t('layout.toHomepage'),
    isContainedButton = false,
    to = localizeUrl('/', isLoggedIn),
    isPage = true,
    onClick
  } = args;
  const buttonAction = onClick ? { onClick } : { to };
  return (
    <ConditionnalPageContainer isPage={isPage}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '20rem',
          width: '100%',
          maxWidth: '35rem',
          mx: 'auto'
        }}
      >
        <Typography variant="h1" sx={{ mb: '2rem', textAlign: 'center' }}>
          {title}
        </Typography>
        {text && (
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            {text}
          </Typography>
        )}
        {richText && <ComponentGeneriqueTexte value={{ Texte: richText }} />}
        <Button sx={{ mt: '2.5rem' }} variant={isContainedButton ? 'contained' : 'outlined'} {...buttonAction}>
          {buttonLabel}
        </Button>
      </Box>
    </ConditionnalPageContainer>
  );
};
