import { Box, Grid, List, ListItem, Stack, SxProps, Typography } from '@mui/material';
import { ElementType, MouseEvent as ReactMouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { FOOTER_HEIGHT } from '../../constants/layout';
import { ReactLink } from '../../models/common';
import { StrapiPiedPageEntity } from '../../models/layout';
import { transformLinks } from '../../utils/tools';
import { Button } from '../atoms/Button';
import { IconButton } from '../atoms/IconButton';
import { ImageStrapi } from '../atoms/ImageStrapi';
import { Link } from '../atoms/Link';
import { IconFacebook } from '../icons/IconFacebook';
import { IconLinkedin } from '../icons/IconLinkedin';
import { IconYoutube } from '../icons/IconYoutube';

import { Container } from './Container';
import { useSocialReport } from '../../hooks/analytic/useSocialReport';

export type FooterProps = {
  piedPage?: StrapiPiedPageEntity;
};

const LinkFooter = ({
  label,
  linkProps
}: {
  label: string;
  linkProps: {
    to?: string;
    component?: ElementType;
    href?: string;
    target?: '_blank';
    onClick?: (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  };
}) => (
  <Link
    key={label}
    {...linkProps}
    sx={{
      color: 'white',
      textDecoration: 'none',
      fontWeight: 600,
      textAlign: { xs: 'center', md: 'left' }
    }}
  >
    {label}
  </Link>
);

const buildFooterLinks = (reactLinks: ReactLink[]) =>
  reactLinks.map(({ label, href, to }) => (
    <ListItem key={label} sx={{ px: 0 }}>
      <LinkFooter label={label} linkProps={to ? { to, component: RouterLink } : { href, target: '_blank' }} />
    </ListItem>
  ));

const IconReseauSocial = ({ reseauSocial }: { reseauSocial: string }) => {
  switch (reseauSocial) {
    case 'LinkedIn':
      return <IconLinkedin />;
    case 'Facebook':
      return <IconFacebook />;
    case 'YouTube':
      return <IconYoutube />;
    default:
      return <IconLinkedin />;
  }
};

const getSocialEvent = (label: string): string => {
  switch (label) {
    case 'LinkedIn':
      return 'social_linkedin';
    case 'Facebook':
      return 'social_facebook';
    case 'YouTube':
      return 'social_youtube';
    default:
      throw new Error('Invalid social network');
  }
};

export const Footer = ({ piedPage }: FooterProps) => {
  const { t } = useTranslation();
  const [socialEvent, setSocialEvent] = useState<string>('');
  useSocialReport({ event: socialEvent });

  if (!piedPage) return null;

  const leftLinks = transformLinks(piedPage.attributes.LiensAGauche ?? []);
  const rightLinks = transformLinks(piedPage.attributes.LiensADroite ?? []);
  const mediaLinks = transformLinks(piedPage.attributes.LiensReseauxSociaux ?? []);

  const footerLinksSx: SxProps = {
    svg: { color: 'secondary.main' },
    '.MuiSvgIcon-root': {
      height: '1rem',
      width: '1rem'
    }
  };

  return (
    <Box
      component="footer"
      role="contentinfo"
      sx={{
        display: 'flex',
        alignItems: 'center',
        minHeight: FOOTER_HEIGHT,
        backgroundColor: 'secondary.main'
      }}
    >
      <Container>
        <Grid
          container
          columnSpacing={1}
          sx={{
            alignItems: { xs: 'center', md: 'stretch' },
            flexDirection: { xs: 'column', md: 'row' },
            py: '2.5rem'
          }}
        >
          <Grid item xs={12} md={3} sx={{ mb: { xs: '1.5rem', md: 0 } }}>
            <ImageStrapi
              strapiImage={piedPage.attributes.Logo}
              alt="logo"
              selectedFormat="thumbnail"
              sx={{ width: 'auto' }}
            />
          </Grid>
          <Grid item xs={12} md={2.5}>
            <List>{buildFooterLinks(leftLinks)}</List>
          </Grid>
          <Grid item xs={12} md={2.5}>
            <List>
              {buildFooterLinks(rightLinks)}
              <ListItem sx={{ px: 0 }}>
                <Button
                  sx={{
                    m: 0,
                    p: 0,
                    fontFamily: 'Open Sans Regular, Arial, sans-serif',
                    fontWeight: 600,
                    color: 'white'
                  }}
                  onClick={() => {
                    if (window.Didomi) {
                      window.Didomi.preferences.show();
                    }
                  }}
                >
                  {t('footer.cookie-consent-choices')}
                </Button>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mt: { xs: '1.5rem', md: 0 } }}>
            <Stack>
              <Stack
                sx={{
                  flexDirection: 'row',
                  alignSelf: { xs: 'center', md: 'flex-end' },
                  columnGap: 1.25
                }}
              >
                {mediaLinks.map((link) => (
                  <IconButton
                    key={link.label}
                    href={link.href}
                    target="_blank"
                    aria-label={link.label}
                    size="small"
                    iconButtonType="white"
                    sx={footerLinksSx}
                    onClick={() => setSocialEvent(getSocialEvent(link.label))}
                  >
                    <IconReseauSocial reseauSocial={link.label} />
                  </IconButton>
                ))}
              </Stack>
              <Typography
                variant="h3"
                sx={{
                  pt: '2rem',
                  pb: '1.25rem',
                  color: 'white',
                  textAlign: { xs: 'center', md: 'right' }
                }}
              >
                {t('footer.fruggrScore')} : {piedPage.attributes.ScoreFruggr}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: 'white',
                  textAlign: { xs: 'center', md: 'right' }
                }}
              >
                © {new Date().getFullYear()} {t('footer.copyright')}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
