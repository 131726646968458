/*pageview*/

export type PageViewData = {
  event: 'pageview';
  page: {
    path: string;
    pagename: string;
    pagetype: string;
    language: string;
    [key: `page_chapter${number}`]: string;
  };
};
/*sign in*/
export type SignInAttemptData = {
  event: 'security_sign_in_attempt';
  userId?: string;
};

export type SignInSuccessData = {
  event: 'security_sign_in_success';
  userId: string;
};

export type SignInFailureData = {
  event: 'security_sign_in_failure';
  userId?: string;
};

/*sing up*/
type SignUpAttemptData = {
  event: 'security_sign_up_attempt';
};

type SignUpSuccessData = {
  event: 'security_sign_up_success';
};

/*social*/
type SocialEvent = 'social_facebook' | 'social_linkedin' | 'social_youtube';

type SocialData = {
  event: SocialEvent;
};

/* learning_test */
type LearningTestData = {
  event: 'learning_test';
  test_interaction: {
    action: 'complete_test' | 'start_test'; // Action effectuée par l'utilisateur
    test_theme: string; // La thématique du test finalisé
  };
};

/*search*/
type SearchFindProps = {
  search_interaction: {
    action: 'search';
    keyword: string;
  };
};

type SearchResultProps = {
  search_interaction: {
    action: 'click_search'; // Action effectuée par l'utilisateur
    keyword: string; // Le mot clé ou l'expression entrée par l'utilisateur
    title: string; // Titre du lien cliqué
    url: string; // URL du lien cliqué
  };
};

type KnowledgeSearchData = {
  event: 'knowledge_search';
} & (SearchFindProps | SearchResultProps);

/*agenda*/
type AgendaRegistrationData = {
  event: 'agenda_registration';
  agenda_interaction: {
    action: 'sign_up'; // Action effectuée par l'utilisateur
    agenda_name: string; // Le nom de l'évènement
    agenda_id: string; // ID de l'évènement
  };
};

/*favorites*/
type FavoritesKnowledgeData = {
  event: 'favorites_knowledge';
  knowledge_interaction: {
    action: 'added' | 'removed'; // Action effectuée par l'utilisateur
    knowledge_name: string; // Le nom de la ressource
  };
};

/*grouping*/
export type SingUpData = SignUpAttemptData | SignUpSuccessData;
export type SignInData = SignInAttemptData | SignInFailureData | SignInSuccessData;

// Définition des types de données possibles, à enrichir au fur et à mesure des demandes clients
export type AnalyticData =
  | PageViewData
  | SignInData
  | SingUpData
  | SocialData
  | LearningTestData
  | KnowledgeSearchData
  | AgendaRegistrationData
  | FavoritesKnowledgeData;

/*
* Les règles à respecter :
- Pas de caractère accentué
- Pas d'espace : utiliser les underscore «_»
- Pas de virgule, de guillemets, d'apostrophes, de «+» et de «&»

Les caractères autorisés :
- Les lettres abcdefghijklmnopqrstuvwxyzenmajuscules ou minuscules
- Les chiffres 123467890
- Les points, slashs, Les moins
- Les tildes ~
* */
export function sanitiseAnalytic(data: { [key: string]: string | object | undefined }): {
  [key: string]: string | object | undefined;
} {
  const sanitisedData: { [key: string]: string | object | undefined } = {};
  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'string') {
      sanitisedData[key] = sanitiseString(value);
    } else if (typeof value === 'object') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      sanitisedData[key] = sanitiseAnalytic({ ...value });
    }
  });
  return sanitisedData;
}

export const sanitiseString = (str?: string): string | undefined => {
  return str
    ?.toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .replace(/,/g, '')
    .replace(/'/g, '')
    .replace(/"/g, '')
    .replace(/&/g, '')
    .replace(/\+/g, '')
    .replace(/\s/g, '_')
    .trim();
};

export function addAnalytic(data: AnalyticData): void {
  window.dataLayer = window.dataLayer || [];
  const sanitisedData = sanitiseAnalytic(data);
  switch (sanitisedData.event) {
    // peut-être push plusieurs fois le même event, on ne veut pas de doublons
    case 'security_sign_in_success': {
      const isAlreadyReport = window.dataLayer.at(-1)?.event === 'security_sign_in_success';
      if (isAlreadyReport) {
        return;
      }
      break;
    }
    default:
      break;
  }
  if (isPageViewData(sanitisedData) && sanitisedData?.page.pagetype === 'brique_savoir') {
    const isAlreadyReport = JSON.stringify(window.dataLayer.at(-1)) === JSON.stringify(sanitisedData);
    if (isAlreadyReport) {
      return;
    }
  }

  window.dataLayer.push(sanitisedData);
}

const isPageViewData = (data: { [p: string]: string | object | undefined }): data is PageViewData => {
  return data.event === 'pageview';
};
