import { SxProps } from '@mui/material';
import { IconArrow } from '../icons/IconArrow';
import { Button } from './Button';

type ArrowLinkProps = React.PropsWithChildren<{
  variant?: 'text' | 'outlined' | 'contained';
  to?: string;
  href?: string;
  label: string;
  isBackArrow?: boolean;
  sx?: SxProps;
}>;

export const ArrowLink = ({ variant, to, href, label, isBackArrow = false, sx = {} }: ArrowLinkProps) => (
  <Button
    variant={variant}
    to={to}
    href={href}
    startIcon={isBackArrow && <IconArrow orientation="left" />}
    endIcon={!isBackArrow && <IconArrow />}
    sx={{ ml: '-0.5rem', ...sx }}
  >
    {label}
  </Button>
);
